// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-abfalle-js": () => import("./../../../src/pages/Abfälle.js" /* webpackChunkName: "component---src-pages-abfalle-js" */),
  "component---src-pages-ablauf-js": () => import("./../../../src/pages/Ablauf.js" /* webpackChunkName: "component---src-pages-ablauf-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bestatigung-js": () => import("./../../../src/pages/Bestätigung.js" /* webpackChunkName: "component---src-pages-bestatigung-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/Booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-danke-js": () => import("./../../../src/pages/Danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-submission-thankyou-js": () => import("./../../../src/pages/submission/thankyou.js" /* webpackChunkName: "component---src-pages-submission-thankyou-js" */),
  "component---src-pages-vorteile-js": () => import("./../../../src/pages/Vorteile.js" /* webpackChunkName: "component---src-pages-vorteile-js" */),
  "component---src-templates-legal-js": () => import("./../../../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */)
}

