module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-173588482-1","cookieName":"gdpr-analytics-enabled","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"GTM-KTC8267","cookieName":"gdpr-analytics-enabled","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"3976832799020941","cookieName":"gdpr-marketing-enabled"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Re.cycular","short_name":"Re.cycular","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icon":"src/1Pics/logo3.jpeg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3472565fae37be33b71cc57907a22632"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
